@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: 'Hanken Grotesk', sans-serif;
  background-color: #f9f9fb;
  background-attachment: fixed;
}

a {
  color: inherit;
  text-decoration: none;
  background-color: rgba(240, 255, 244, 0.402);
  /* color: #a4ffb9; */
  color: #20282e;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
